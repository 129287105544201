import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import {Button, InputRef, Popover, Space, Tooltip} from "antd";
import {Comment, CommentType} from "../../generated/api";
import {visit} from 'unist-util-visit'
import {Parent} from "unist";
import {DislikeOutlined, LikeOutlined, MessageOutlined} from "@ant-design/icons";
import {defaultHandlers} from 'mdast-util-to-hast'
import {trimLines} from "trim-lines";
import {Element, Text} from "hast";
import {State} from "mdast-util-to-hast/lib/handlers/root";
import {MdastText} from "mdast-util-to-hast/lib/handlers/text";
import TextArea from "antd/es/input/TextArea";


//TODO: ESC behavior
//TODO: hiding popup behavior (click outside)
interface TooltipVisibilityState {
    [key: number]: boolean;
}

interface AnchorProps {
    children: React.ReactNode;

    [key: string]: any;
}

interface LinePosition {
    line: number,
    column: number
}

interface Position {
    start: LinePosition,
    end: LinePosition
}

function HighlightableMarkdown(props: {
    content: any;
    userComments: Comment[] | undefined;
    addComment: (comment: Comment) => void;
    commentClick: (commentId: string) => void;
    tabContentScroll: () => void;
    tabContentWheel: () => void;
    tabName: string;
    executionId: string;
    activeTab: string | undefined
}) {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const rootContainerRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<InputRef | null>(null);
    const [commentValue, setCommentValue] = useState("");
    const [, setSelectedText] = useState("");
    const [showInputComment, setShowInputComment] = useState(false);
    const [newCommentPosition, setNewCommentPosition] = useState<Position>()
    const [isMouseDown, setIsMouseDown] = useState(false); // State to track if mouse is down
    const [currentSelection, setCurrentSelection] = useState<{
        pos: Position,
        top: number,
        left: number,
        text: string,
        reverted: boolean
    } | undefined>()
    const [currentHover, setCurrentHover] = useState<{
        top: number,
        left: number,
        text: string
    } | undefined>()

    const [comments, setComments] = useState<{
        id: string,
        pos: Position
        comment: string
    }[]>()
    const [references, setReferences] = useState<string[]>([]);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);

    useEffect(() => {
        const handleMouseDown = () => {
            setIsMouseDown(true);
        };

        const handleMouseUp = () => {
            setIsMouseDown(false);
        };

        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    useEffect(() => {
        const referencesData = document.getElementById('references-data');
        if (referencesData) {
            setReferences(JSON.parse(referencesData.textContent!));
        }
    }, [props.content]);

    const handleClick = (index: number) => {
        setVisibleTooltip(visibleTooltip === index ? null : index);
    };

    // const customRenderers = {
    //     a: ({children, ...props}: AnchorProps) => {
    //         const index = parseInt(props['data-index'], 10);
    //         return (
    //             <Tooltip
    //                 title={references[index]}
    //                 open={visibleTooltip === index}
    //                 onOpenChange={() => handleClick(index)}
    //             >
    //                 <a
    //                     {...props}
    //                     onClick={(e) => {
    //                         e.preventDefault();
    //                         handleClick(index);
    //                     }}
    //                 >
    //                     {children}
    //                 </a>
    //             </Tooltip>
    //         );
    //     },
    // };

    function handleOnCommentClick(event: any) {
        const commentElement = event.target.closest('.comment');
        if (commentElement) {
            const commentId = commentElement.getAttribute('comment-id');
            if (commentId && props.commentClick) {
                props.commentClick(commentId);
            }
        }
    }


    useEffect(() => {
        if (props.userComments == undefined) return;
        const comms = props.userComments.filter(com => com.tabName === props.tabName).filter(d => d.position != undefined).map(com => {
            return {id: com.id?.toString() ?? '', pos: com.position!, comment: com.userComment}
        })
        setComments(comms)
    }, [props.userComments])


    function addComment(type: CommentType) {
        setComments((oldComments) => {
            if (!currentSelection) return oldComments
            props.addComment({
                tabName: props.activeTab ?? "",
                text: currentSelection.text,
                userComment: commentValue,
                startPosition: 0,
                endPosition: 0,
                position: currentSelection.pos,
                type: type
            })
            return [...oldComments ?? [], {
                id: Math.random().toString(),
                comment: commentValue,
                pos: currentSelection.pos
            }];
        })
        setCurrentSelection(undefined)
        setShowInputComment(false)
        setCommentValue("")
        setNewCommentPosition(undefined)
    }

    function getPos(node: HTMLElement | null, offset: number): {
        line: number,
        column: number
    } | undefined {
        while (node !== null) {
            if (node.attributes) {
                const sourcePos = node.attributes.getNamedItem('data-sourcepos');
                if (sourcePos) {
                    const sourcePosValue = sourcePos.value;
                    const [start, end] = sourcePosValue.split('-');
                    const [startLn, startPs] = start.split(':');
                    const [endLn, endPs] = end.split(':');

                    if (startLn !== endLn) {
                        // Handling when start line is not equal to end line
                        if (offset === 0) {
                            // If offset is 0, consider the start position
                            return {
                                line: Number(startLn),
                                column: Number(startPs)
                            }
                        } else {
                            // In other cases, continue to search parent elements
                            node = node.parentElement;
                            continue;
                        }
                    }

                    const startPosNumber = Number(startPs);
                    const endPosNumber = Number(endPs);

                    if (offset <= endPosNumber - startPosNumber + 1) {
                        return {
                            line: Number(startLn),
                            column: startPosNumber + offset
                        }
                    }
                }
            }
            node = node.parentElement;
        }
        return undefined;
    }

    const handleTextSelection = useCallback((e: { offsetX?: number, offsetY?: number, target?: EventTarget | null }) => {
        const selection = window.getSelection()
        if (containerRef.current && selection?.focusNode && selection?.anchorNode
            && (selection.focusNode !== selection.anchorNode || selection.focusOffset !== selection.anchorOffset)) {
            const textContent = selection.getRangeAt(0).cloneContents().textContent
            setSelectedText(textContent ?? '');
            let end = getPos(selection.focusNode as HTMLElement, selection.focusOffset);
            let start = getPos(selection.anchorNode as HTMLElement, selection.anchorOffset);
            let reverted = false;
            if (textContent && end && start) {
                if (end.line === start.line && end.column === start.column) {
                    // no selection
                } else {
                    if (end.line < start.line || (end.line === start.line && end.column < start.column)) {
                        [start, end] = [end, start]
                        reverted = true
                    }
                    const clientRect = selection.getRangeAt(0).getClientRects()[0];
                    const elRect = containerRef.current.getBoundingClientRect();
                    const x = clientRect.x - elRect.x
                    const y = clientRect.y - elRect.y + 10
                    setCurrentSelection({
                        pos: {start, end},
                        top: y,
                        left: x,
                        text: textContent,
                        reverted
                    })
                }
            }
        } else {
            //TODO: just click, no selection
        }
    }, [])

    useEffect(() => {
        const cur = containerRef.current
        if (cur) {
            cur.addEventListener('mouseup', handleTextSelection);
            const savedHandleTextSelection = handleTextSelection
            return () => {
                cur.removeEventListener('mouseup', savedHandleTextSelection);
            };
        }
    }, [handleTextSelection]);

    useEffect(() => {
        setCurrentSelection(undefined)
    }, [props.activeTab])

    function rehypeReferences() {
        return (tree: any) => {
            let referenceCount = 0;
            const references: any = [];

            visit(tree, 'text', (node, index, parent) => {
                const {value} = node;
                const matches = value.match(/\{\{(.*?)\}\}/g);
                if (matches) {
                    const parts = value.split(/(\{\{.*?\}\})/g);
                    const newChildren = parts.map((part: any) => {
                        if (part.match(/\{\{.*?\}\}/)) {
                            const refText = part.slice(2, -2);
                            referenceCount += 1;
                            references.push(refText);
                            return {
                                type: 'element',
                                tagName: 'a',
                                properties: {
                                    className: 'reference',
                                    href: `#reference-${referenceCount}`,
                                    'data-tooltip': refText,
                                    'data-index': referenceCount - 1,
                                },
                                children: [{type: 'text', value: `[${referenceCount}]`}],
                            };
                        } else {
                            return {type: 'text', value: part};
                        }
                    });
                    parent.children.splice(index, 1, ...newChildren);
                }
            });

            tree.children.push({
                type: 'element',
                tagName: 'script',
                properties: {type: 'application/json', id: 'references-data'},
                children: [
                    {
                        type: 'text',
                        value: JSON.stringify(references),
                    },
                ],
            });
        };
    }
    function addSelection() {
        function splitValue(text: string, elementPos: Position, positions: LinePosition[]) {
            if (!elementPos) return [text]
            if (elementPos.start.line !== elementPos.end.line) return [text]
            if (positions.filter(x => x.line !== elementPos.start.line).length > 0) return [text]
            if (positions.filter(x => x.column < elementPos.start.column || x.column > elementPos.end.column).length > 0) return [text]
            let ret: { value: string, position: Position }[] = []
            let reminder = text
            let currentStart = elementPos.start.column
            positions.forEach(x => {
                if (x.column > currentStart) {
                    ret.push({
                        value: reminder.slice(0, x.column - currentStart),
                        position: {
                            start: {
                                line: elementPos.start.line,
                                column: currentStart
                            },
                            end: {
                                line: elementPos.start.line,
                                column: x.column - 1
                            }
                        }
                    })
                }
                reminder = reminder.slice(x.column - currentStart)
                currentStart = x.column
            })
            if (reminder.length > 0) ret.push({
                value: reminder,
                position: {
                    start: {
                        line: elementPos.start.line,
                        column: currentStart
                    },
                    end: {
                        line: elementPos.end.line,
                        column: elementPos.end.column
                    }
                }
            })
            return ret.map(x => ({
                type: 'element',
                tagName: 'span',
                position: x.position,
                children: [{
                    type: 'text',
                    value: x.value
                }]
            }))

        }

        function intersectionType(element: Position, selection: Position) {
            if (element.start.line === element.end.line) {
                const elementStart = element.start.line * 10000 + element.start.column
                const elementEnd = element.end.line * 10000 + element.end.column
                const selectionStart = selection.start?.line * 10000 + selection.start?.column
                const selectionEnd = selection.end?.line * 10000 + selection.end?.column
                if (elementStart >= selectionStart && elementEnd <= selectionEnd) {
                    // XXXX <- Selection
                    //  XX  <- Element
                    return 'cover'
                } else if (selectionStart <= elementStart && selectionEnd >= elementStart) {
                    //  XX <- Selection
                    // XXXX <- Element
                    return 'in'
                } else if (selectionStart <= elementEnd && selectionEnd >= elementEnd) {
                    //  XXXX <- Selection
                    // XX    <- Element
                    return 'end'
                } else if (selectionStart >= elementStart && selectionEnd <= elementEnd) {
                    // XXXX <- Selection
                    //    XX <- Element
                    return 'start'
                } else
                    // XX <- Selection
                    //    XX <- Element
                    return 'no'
            } else {
                return 'no' //TODO: there are no such cases for markdown remark-rehype
            }
        }

        return (tree: Parent<Element | Text>) => {
            // loop through all selections
            const allRanges = [...(newCommentPosition ? [{
                pos: newCommentPosition,
                id: 'new'
            }] : []), ...(comments ?? [])]
            allRanges.forEach((s: { pos: Position, id: string }) => {
                visit(tree, 'text', (node, index, parent) => {
                    if (parent?.position && index !== null && node?.value) {
                        // we have to put "any" here just because we are filtering by Text nodes but returning Element
                        const common: any = {
                            type: 'element',
                            tagName: 'span',
                            position: parent.position,
                        }
                        switch (intersectionType(parent.position, s.pos)) {
                            case 'in':
                                parent.children[index] = {
                                    ...common,
                                    children: splitValue(node.value.toString(), parent.position, [s.pos.end])
                                }
                                break
                            case 'end':
                                parent.children[index] = {
                                    ...common,
                                    properties: {'class': 'partial'},
                                    children: splitValue(node.value.toString(), parent.position, [s.pos.start])
                                }
                                break
                            case 'start':
                                parent.children[index] = {
                                    ...common,
                                    properties: {'class': 'partial'},
                                    children: splitValue(node.value.toString(), parent.position, [s.pos.start, s.pos.end])
                                }
                                break
                        }
                    }
                })
            })
            allRanges.forEach(s => {
                visit(tree, 'text', function (node, index, parent) {
                    if (parent?.position && index !== null) {
                        if (intersectionType(parent.position, s.pos) === 'cover') {
                            const child: Element = {
                                type: 'element',
                                tagName: 'span',
                                properties: s.id === 'new' ? {'class': 'current'} : {
                                    'comment-id': s.id,
                                    'class': 'comment',
                                    'data-comment-id': s.id,
                                },
                                position: parent.position,
                                children: [node]
                            }
                            parent.children[index] = child as any;
                        }
                    }
                })
            })
        }
    }

    function newComment(commentType: CommentType) {
        if (currentSelection && commentType == CommentType.Comment) {
            setNewCommentPosition(currentSelection.pos);
            setShowInputComment(true);
        } else if (currentSelection) {
            setNewCommentPosition(currentSelection.pos);
            addComment(commentType)
        }
    }

    useEffect(() => {
        if (showInputComment) inputRef.current?.focus();

    }, [showInputComment])

    return (
        <div className={'highlightable-markdown-wrapper'} onWheel={() => {
            props.tabContentWheel()
        }} onScroll={() => {
            if (isMouseDown) props.tabContentWheel()
            props.tabContentScroll()
        }} style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, overflow: 'auto'}}
             ref={rootContainerRef}>
            <Popover
                arrow={false}
                open={!!currentSelection}
                defaultOpen={false}
                trigger={"click"}
                placement={"topLeft"}
                overlayInnerStyle={{padding: '5px'}}
                onOpenChange={(open) => {
                    if (!open) {
                        setCurrentSelection(undefined)
                        setCommentValue("")
                        setShowInputComment(false)
                        setNewCommentPosition(undefined)
                    }
                }}
                content={
                    showInputComment ? <Space.Compact style={{width: 300}}>
                            <div>
                                <TextArea
                                    style={{width: 300, resize: 'none'}}
                                    rows={2}
                                    ref={inputRef}
                                    value={commentValue}
                                    onChange={e => setCommentValue(e.target.value)}
                                    placeholder="Add your comment here"
                                />
                                <div>
                                    <Button disabled={!commentValue}
                                            style={{marginTop: 3}}
                                            onClick={() => addComment(CommentType.Comment)} type="primary">
                                        Add
                                    </Button>

                                </div>
                            </div>
                        </Space.Compact>
                        :
                        <div>
                            <Tooltip title="Add comment">
                                <Button onClick={() => newComment(CommentType.Comment)} type={"link"} size={"small"}>
                                    <MessageOutlined/>
                                </Button>
                            </Tooltip>
                            <Button onClick={() => newComment(CommentType.ThumbsUp)} type={"link"} size={"small"}>
                                <LikeOutlined/>
                            </Button>
                            <Button onClick={() => newComment(CommentType.ThumbsDown)} type={"link"} size={"small"}>
                                <DislikeOutlined/>
                            </Button>
                        </div>
                }
            >
                <div style={currentSelection ? {
                    position: 'absolute',
                    top: currentSelection.top,
                    left: currentSelection.left
                } : {}}></div>
            </Popover>
            <Popover
                open={!!currentHover}
                defaultOpen={false}
                // trigger={"click"}
                onOpenChange={(open) => {
                    if (!open) {
                        setCurrentHover(undefined)
                    }
                }}
                content={
                    <div>{currentHover?.text}</div>
                }
            >
                <div style={
                    currentHover ? {position: 'absolute', top: currentHover.top, left: currentHover.left} : {}
                }/>
            </Popover>
            <div onClick={handleOnCommentClick} style={{marginTop: ""}} ref={containerRef} className={"main-panel"}>

                <div
                    // contentEditable={true}
                    onCut={e => {
                        e.preventDefault();
                        return false;
                    }}
                    onPaste={e => {
                        e.preventDefault();
                        return false;
                    }}
                    suppressContentEditableWarning={true}
                    onKeyDown={e => {
                        if (!e.metaKey && !e.ctrlKey &&
                            !(["ArrowDown", "ArrowUp", "ArrowRight", "ArrowLeft", "Home", "End", "PageUp", "PageDown"].includes(e.key))
                        ) {
                            e.preventDefault()
                        } else {
                            //TODO: Esc behavior
                            setTimeout(() => handleTextSelection({}), 0)
                        }
                        return e.metaKey
                    }}
                     onMouseOver={e => {
                         const target = e.target as HTMLElement
                         if (target?.classList && target.classList.contains("comment")) {
                             let target: any = e.target
                             let elem = target
                             while (elem && !elem.classList.contains("root-container")) {
                                 elem = elem.offsetParent
                             }
                             if (elem) {
                                 const commendId = target.getAttribute("comment-id")
                                 if (comments) {
                                     const text = comments.find(x => x.id === commendId)?.comment
                                     if (text) {
                                         const rect = elem.getBoundingClientRect();
                                         let x = e.clientX - rect.left
                                         let y = e.clientY - rect.top
                                         setCurrentHover({
                                             text: text,
                                             left: x,
                                             top: y
                                         })
                                     }
                                 }
                             }
                         }
                     }} onMouseOut={() => {
                    setCurrentHover(undefined)
                }}
                >

                    <ReactMarkdown
                        key={comments?.length}
                        rawSourcePos={true}
                        sourcePos={true}
                        linkTarget={(link) => {
                            return link.includes("autonomous.health") ? "_self" : "_blank"
                        }}
                        includeElementIndex={true}
                        rehypePlugins={[addSelection, rehypeReferences]}
                        remarkRehypeOptions={{
                            handlers: {
                                ...defaultHandlers,
                                'text': ((state: State, node: MdastText) => {
                                    const result: Element = {
                                        type: 'element',
                                        tagName: 'span',
                                        properties: {},
                                        children: [{type: 'text', value: trimLines(String(node.value))}]
                                    }
                                    state.patch(node, result)
                                    return state.applyData(node, result)
                                })
                            }
                        } as any}
                    >
                        {props.content}</ReactMarkdown>
                </div>
            </div>
        </div>
    );
}

export default HighlightableMarkdown;
